<template>
    <div>
        <!-- 1L-->
        <van-cell-group>
            <van-field
                    v-model="user.Name"
                    required
                    clearable
                    label="用户名"
                    placeholder="请输入用户名"
                    :bind:input="getUserName"
            />

            <van-field
                    v-model="user.LoginPwd"
                    type="password"
                    label="密码"
                    placeholder="请输入密码"
                    clearable
                    required
                    :bind:input="getPassword"
            />
            <div :style="{'text-align':'center','vertical-align': 'center'}">
                <van-button :class="'btn_save'"  plain hairline type="primary" @click="save">保存</van-button>
            </div>
        </van-cell-group>
<!--    二维码图片    -->
<!--        <div v-if="this.$client.WEIXIN" :style="{'text-align':'center','vertical-align': 'center'}">-->
<!--            <img width="100"-->
<!--                       height="100" class="miniprogram" src="../../assets/imfycIcon/miniprogram.jpg" />-->
<!--        </div>-->

    </div>
</template>

<script>

    import {CellGroup,Card, Field, Button, Sticky, Tag, Toast} from 'vant';
    import {isEmpty} from "../../utils/common";

    export default {
        name: "info",
        components: {
            [CellGroup.name]: CellGroup,
            [Tag.name]: Tag,
            [Sticky.name]: Sticky,
            [Field.name]: Field,
            [Button.name]: Button,
            [Card.name]: Card,
        },
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                userId: null,
                user: {},
            };
        }

        , mounted() {
            Toast.loading('加载中。。。');
            this.userId = this.$route.params.userId;
            if (isEmpty(this.userId)) {
                let tmp = JSON.parse(sessionStorage.getItem('USER'));
                if (isEmpty(tmp)) {
                    this.getUserInfo();
                    // Toast.fail('无法获取当前用户');
                    return;
                }
                this.userId = tmp.ID;
            }
            //
            this.onLoad();
        }
        , methods: {
            onLoad() {
                //
                this.getUserInfo();
            }
            //
            , getUserInfo() {
                let user = {};
                user.ID = isEmpty(this.userId) ? '2031' : this.userId;
                this.$api.api.req(this.$api.user.getUser(user)).then(resp => {
                    //
                    if (isEmpty(resp) || isEmpty(resp.data)) {
                        Toast.fail('用户信息获取失败');
                        return;
                    }
                    //
                    let count = resp.data.length;
                    if (count > 0) {
                        this.user = resp.data[0];
                        if (this.user.LoginPwd != null) {
                            this.user.LoginPwd = 'thisis'
                        }
                    }
                });
            }
            //获得用户名
            , getUserName(val) {
                this.user.Name = val;
            }
            //获得密码
            , getPassword(val) {
                this.user.LoginPwd = val;
            }
            //保存
            , save() {
                //查询用户名是否存在
                this.getUser();
            }
            //查询用户
            , getUser() {
                let user = {};
                user.NotUserID = this.user.ID;
                user.Name = this.user.Name;
                this.$api.api.req(this.$api.user.getUser(user)).then(resp => {
                    //
                    if (isEmpty(resp) || isEmpty(resp.data)) {
                        Toast.fail('保存失败');
                        return;
                    }
                    //
                    let data = [];
                    data = resp.data
                    if (data.length > 0) {
                        Toast.fail('用户名已存在');
                    } else {
                        this.updateUser();
                    }
                });
            }
            //更新用户
            , updateUser() {
                let user = {};
                user.ID = this.user.ID
                user.Name = this.user.Name;
                if ('thisis' != this.user.LoginPwd) {
                    user.LoginPwd = this.user.LoginPwd;
                }
                this.$api.api.req(this.$api.user.updateUser(user)).then(resp => {
                    //
                    if (isEmpty(resp) || isEmpty(resp.data)) {
                        Toast.fail('保存失败');
                        return;
                    }
                    //
                    Toast.success({
                        message: '保存成功', duration: 1000, onClose: () => {
                            this.$router.go(-1);
                        }
                    });
                });
            }
        }
    }
</script>

<style scoped>
    .btn_save {
        width: 60%;
        display: inline-block;
        height: auto;
        /*margin: 5px;*/
        /*height: 0.66667rem;*/
        /*line-height: 0.66667rem;*/
        text-align: center;

        margin-bottom: 0.6rem;
        margin-top: 0.6rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }
</style>
